import React from "react"

export const About = () => {
  return (
    <div className="flex flex-wrap lg:justify-between lg:items-center">
      <div>
        <h3 className="text-xl lg:text-4xl font-bold text-pink-500 tracking-tight">
          What's Lollipop Lab?
        </h3>
        <p className="text-pink-800 font-medium text-base lg:text-lg pb-4 lg:pb-0">
          Lollipop Lab では、日々推しを愛でたり技術系同人誌を作ったりしています。
        </p>
        <p className="text-pink-800 font-medium text-base lg:text-lg pb-4 lg:pb-0">
          姉妹サークル（？）「<a target="_blank" className="underline" href="https://michele-lap.azurewebsites.net/arcam.html">フェニックス財団 アーカム研究所</a>」もチェックしてね♪
        </p>
        <p className="text-pink-800 font-medium text-base lg:text-lg pb-4 lg:pb-0 my-2">
          📨連絡先: lollipop [at] buu.me
        </p>
      </div>
    </div>
  )
}
